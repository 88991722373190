import './header.scss'
let Header=()=> {
  return (
    <div className="headerContainer">
      <div className="headband"></div>
      <div className="headerLogoContainer">
        <img alt="logo" src="/logo.png" className="headerLogo" />
      </div>
      <div className="headerTitleContainer">
      </div>
    </div>
  );
}
export default Header;