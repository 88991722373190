import React, { useEffect } from 'react';
import { Page, Text, View, Document, Image,Font,StyleSheet ,BlobProvider , PDFDownloadLink} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { v4 as Guid } from 'uuid';



const PdfQuestionnaryDocument = ({imgUriArray,questionnary_user_info}) => {
  // const [instance, update] = usePDF({ document });
  useEffect(() => {
    // console.log('img ready')
    // console.log(imgUriArray)
    // console.log(questionnary_user_info)
  }, [imgUriArray]);
  
  const getTextColor = (chartId,scoreChartId) => {
    try {
      let scoreToGetColor = Math.round(questionnary_user_info.chart[chartId].scoreChart[scoreChartId])
      
      let colorToReturn = questionnary_user_info.chart[chartId].score_sentence.filter(score=>{
        if(scoreToGetColor>=score.min && scoreToGetColor<=score.max){
          return score.color
        }
      })
      
      return `${colorToReturn[0].color}`
    } catch (error) {
      // console.log(error)
      return ''
    }
    
  }
  const getStyle = (chartId,scoreChartId) => {
    try {
      let scoreToGetColor = Math.round(questionnary_user_info.chart[chartId].scoreChart[scoreChartId])
      
      let colorToReturn = questionnary_user_info.chart[chartId].score_sentence.filter(score=>{
        if(scoreToGetColor>=score.min && scoreToGetColor<=score.max){
          return score.color
        }
      })
      
      return {...styles.textEval, color:`${colorToReturn[0].color}`}
    } catch (error) {
      // console.log(error)
      return ''
    }
    
  }
  const getScoreValue=(chartId,scoreChartId)=>{
    try {
      return Math.round(questionnary_user_info.chart[chartId].scoreChart[scoreChartId])
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getIndicatorTitle=(chartId)=>{
    try {
      let indicatorTitle =questionnary_user_info.chart[chartId].indicator_title
      return `> ${indicatorTitle}`
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getScoreSentence=(chartId)=>{
    try {
      let scoreToGetSentence = Math.round(questionnary_user_info.chart[chartId].scoreChart[0])
      let SentenceToReturn = questionnary_user_info.chart[chartId].score_sentence.filter(score=>{
      if(scoreToGetSentence>=score.min && scoreToGetSentence<=score.max){
        return score.color
      }
      })
    return SentenceToReturn[0].sentence
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getScoreSentenceColor=(chartId)=>{
    try {
      let scoreToGetSentenceColor = Math.round(questionnary_user_info.chart[chartId].scoreChart[0])
      let SentenceColorToReturn = questionnary_user_info.chart[chartId].score_sentence.filter(score=>{
        if(scoreToGetSentenceColor>=score.min && scoreToGetSentenceColor<=score.max){
          return score.color
        }
      })
    return SentenceColorToReturn[0].color
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getTimeElasped=()=>{
    try {
      let timeElapsedBrut = questionnary_user_info.time_elapsed_in_seconds
      let formattedTimeElapsed = `${parseInt(timeElapsedBrut.split(":")[0])>0?timeElapsedBrut.split(":")[0]+'h ':''}${parseInt(timeElapsedBrut.split(":")[1])>0?timeElapsedBrut.split(":")[1]+'min ':''}${parseInt(timeElapsedBrut.split(":")[2])>0?timeElapsedBrut.split(":")[2]+'s':''}`
      return formattedTimeElapsed
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getScoreSentenceDefinition=(chartId)=>{
    try {
      let SentenceDefinitionToReturn = questionnary_user_info.chart[chartId].chart_definition
    return SentenceDefinitionToReturn
    } catch (error) {
      // console.log(error)
    }
    
  }
  const getScoreEval=(chartId,scoreId)=>{
    let chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
      return chartInfo.chart_id===chartId
    })
    const keyToUse= Object.keys(chartDataToUse[0].score_sentence[scoreId])[0]
    let scoreEvalToReturn = Math.round(chartDataToUse[0].score_sentence[scoreId][keyToUse].score[0])
    return scoreEvalToReturn
  }

  const getWorstScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length==0){
        return ''
      }
      const key = chartScoreToUse[0].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key].worst.color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getWorstScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length==0){
        return ''
      }
      // console.log(chartScoreToUse)
      const key = chartScoreToUse[0].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      // console.log(scoreInfo[key].worst.text)
      return scoreInfo[key].worst.text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getSecondWorstScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<2){
        return ''
      }
      const key = chartScoreToUse[1].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getSecondWorstScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<2){
        return ''
      }
      const key = chartScoreToUse[1].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getThirdWorstScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<3){
        return ''
      }
      const key = chartScoreToUse[2].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getThirdWorstScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<3){
        return ''
      }
      const key = chartScoreToUse[2].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getFourthWorstScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<4){
        return ''
      }
      const key = chartScoreToUse[3].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getFourthWorstScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])<74
      }).sort((a,b)=>{
        return a.scoreChart[0]-b.scoreChart[0]
      })
      if(chartScoreToUse.length<4){
        return ''
      }
      const key = chartScoreToUse[3].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-worst'].text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getBestScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])>=74
      }).sort((a,b)=>{
        return b.scoreChart[0]-a.scoreChart[0]
      })
      if(chartScoreToUse.length==0){
        return ''
      }
      const key = chartScoreToUse[0].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key].best.text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getBestScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])>=74
      }).sort((a,b)=>{
        return b.scoreChart[0]-a.scoreChart[0]
      })
      if(chartScoreToUse.length==0){
        return ''
      }
      const key = chartScoreToUse[0].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      }) 
      return scoreInfo[key].best.color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getSecondBestScoreSentence=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])>=74
      }).sort((a,b)=>{
        return b.scoreChart[0]-a.scoreChart[0]
      })
      if(chartScoreToUse.length<2){
        return ''
      }
      const key = chartScoreToUse[1].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      })
      return scoreInfo[key]['alt-best'].text
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getSecondBestScoreColor=(chartId)=>{
    try {
      const chartDataToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartInfo.chart_id===chartId
      })
      let chartScoreToUse = questionnary_user_info.chart.filter(chartInfo=>{
        return chartDataToUse[0].question_to_ponderate.includes(chartInfo.chart_id)
      })
      chartScoreToUse=chartScoreToUse.filter(score=>{
        return Math.round(score.scoreChart[0])>=74
      }).sort((a,b)=>{
        return b.scoreChart[0]-a.scoreChart[0]
      })
      if(chartScoreToUse.length<2){
        return ''
      }
      const key = chartScoreToUse[1].chart_id

      let scoreInfo = chartDataToUse[0].score_sentence.find(score=>{
        return Object.keys(score)[0]===key
      }) 
      return scoreInfo[key]['alt-best'].color
    } catch (error) {
      // console.log(error)
      return ''
    }
  }
  const getLogoActualColorToUse=(score)=>{
    if(score>=0 && score<50){
      return "/pdf/actualResultLogoBad.png"
    }else if(score>=50 && score<74){
     return "/pdf/actualResultLogoMedium.png"
    }else if(score>=74 && score<=100){
     return "/pdf/actualResultLogoGood.png"
    }else{
      return "/pdf/actualResultLogoBad.png"
    }
  }
  const getLogoPreviousColorToUse=(score)=>{
    if(score>=0 && score<50){
     return "/pdf/previousResultLogoBad.png"
    }else if(score>50 && score<74){
      return "/pdf/previousResultLogoMedium.png"
    }else if(score>=74 && score<=100){
      return "/pdf/previousResultLogoGood.png"
    }else{
      return "/pdf/previousResultLogoBad.png"
    }
  }
  const getBase64ImgUri=(chartId)=>{
    let imgUri= imgUriArray.filter(img=>{
      let currentChartId=Object.keys(img)[0]
      return currentChartId===chartId
    })
    // console.log('image')
    return imgUri[0][chartId]
  }
  const getQuestionUser=(index)=>{
    let guid = generateGuid();
    return( 
      <Text key={guid}>{questionnary_user_info.questionList[index].questionText}</Text>
      )
  }
  const getAnswersUser=(index)=>{
   
    return( 
      questionnary_user_info.responses[index].answerList.map((answer,index)=>{
        let guid = generateGuid();
        return(
          <Text key={guid} style={{marginBottom:25}}>{index+1} : {answer}</Text>
        )
      }))
  }
  let GuidList=[]
  const generateGuid=()=>{
    let guid = Guid();
    if(GuidList.includes(guid)){
      return generateGuid()
    }
    
    GuidList.push(guid)
    return guid
  }
  const generateRestOfQuestions=()=>{
    let questionsAlreadyDone=[]
    return questionnary_user_info.questionList.map((question,index)=>{
      let guid1 = generateGuid();
      let guid2 = generateGuid();
      let guid3 = generateGuid();
      let guid4 = generateGuid();
      let guid5 = generateGuid();
      let guid6 = generateGuid();
      let guid7 = generateGuid();
      if(index>0 && !questionsAlreadyDone.includes(index)){
        questionsAlreadyDone.push(index)
        questionsAlreadyDone.push(index+1)
        return (
          <View key={guid1} style={{display:'flex',flexDirection:'column', justifyContent:'center'}} break >
                <View key={guid2} style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
                  <View key={guid3} style={styles.indicatorContainer3}>
                      {getQuestionUser(index)}
                  </View>
                  <View key={guid4} style={styles.indicatorContainer3}>
                      {getAnswersUser(index)}
                  </View>
                </View>
                <View style={styles.softSeparator}></View>
                <View key={guid5} style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
                  <View key={guid6} style={styles.indicatorContainer3}>
                      {getQuestionUser(index+1)}
                  </View>
                  <View key={guid7} style={styles.indicatorContainer3}>
                      {getAnswersUser(index+1)}
                  </View>
                </View>
          </View>

        )
      }
    })
    
  }
  return(
    <Document>
      {/* page 1 */}
    <Page style={styles.body} dpi={96}>
      <View style={styles.header} fixed>
      <Text style={styles.headerText} >Évaluez vos capacités commerciales en vente récurrente </Text>
      </View>
      <Image
        style={styles.image}
        src="/pdf/headerImg.png"
      />
      
      <Text style={styles.titleFirstPage}>
        COORDONNÉES
      </Text>
      <Text style={styles.separator}>
          ......................................................................................................................................................
      </Text>
      <View style={styles.coordonate}>
          <View style={styles.subcoordonate}>
            <Text style={styles.subcoordonateText2}>Adresse email :</Text> 
            <Text style={styles.subcoordonateText2} >Prénom : </Text> 
            <Text style={styles.subcoordonateText2}>Nom : </Text>
            
          </View>
          <View style={styles.subcoordonate}>
            <Text style={styles.subcoordonateText2} >{questionnary_user_info.user_email} </Text>
            <Text style={styles.subcoordonateText2} >{questionnary_user_info.user_lastname}</Text>
            <Text style={styles.subcoordonateText2} >{questionnary_user_info.user_firstname}</Text>
          </View>
      </View>
      <View style={styles.coordonate}>
        <Text style={styles.timer}>
          Questionnaire terminé en {getTimeElasped()} 
        </Text>
      </View>
      
      
      {/* page 2 */}
      <View style={styles.blockEval} break>
        <Text style={styles.subDate}>
        {questionnary_user_info.date_start_formatted}
        </Text>
        <Text style={styles.titleEval}>
          1. Analyse générale des capacités commerciales de {questionnary_user_info.user_lastname} {questionnary_user_info.user_firstname}
        </Text>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          1.A. Niveau de vos capacités commerciales en vente récurrente aux professionnels
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('1.A')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(0,0))}
                />
                <View>
                  <Text style={{...getStyle(0,0)}}>{getScoreValue(0,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(0,1))}
                />
                <View>
                  <Text style={{...getStyle(0,1)}}>{getScoreValue(0,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(0,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(0)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(0)}}>{getScoreSentence(0)}</Text>
        </View>
      </View>
      {/* page 3 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          1.B. Capacités sur la prospection de nouveaux clients
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('1.B')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(1,0))}
                />
                <View>
                  <Text style={{...getStyle(1,0)}}>{getScoreValue(1,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(0,1))}
                />
                <View>
                  <Text style={{...getStyle(1,1)}}>{getScoreValue(1,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(1,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(1)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(1)}}>{getScoreSentence(1)}</Text>
        </View>
      </View>
      {/* page 4 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          1.C. Capacités sur le développement de clients existants
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('1.C')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(2,0))}
                />
                <View>
                  <Text style={{...getStyle(2,0)}}>{getScoreValue(2,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
                <View style={styles.textEvalSubContainer}>
                <Image
                    style={styles.resultLogo}
                    src={getLogoPreviousColorToUse(getScoreValue(2,1))}
                />
                <View>
                  <Text style={{...getStyle(2,1)}}>{getScoreValue(2,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}

              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(2,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(2)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(2)}}>{getScoreSentence(2)}</Text>
        </View>
      </View>
      {/* page 5 */}
      <View  break>
          <Text style={styles.titleEval2}>
            2. Analyse détaillée sur vos capacités générales 
          </Text>
        <View style={styles.blockEval} >
        
          <View style={styles.subBlockEval}>
            
            <Text style={styles.subtitleEval}>
            2.A. Niveau de maîtrise des techniques de vente
            </Text>
            <View style={styles.chart1A}>
              <Image
                style={styles.chart}
                cache={false}
                src={getBase64ImgUri('2.A')}
              />
              <View style={styles.textEvalContainer}>
                <View style={styles.textEvalSubContainer}>
                  <Image
                    style={styles.resultLogo}
                    src={getLogoActualColorToUse(getScoreValue(3,0))}
                  />
                  <View>
                    <Text style={{...getStyle(3,0)}}>{getScoreValue(3,0)}%</Text>
                    <Text style={styles.textEval}>Résultat actuel </Text>
                  </View>
                </View>
                {questionnary_user_info.totalQuestionnaryDone>1 &&
                <View style={styles.textEvalSubContainer}>
                  <Image
                    style={styles.resultLogo}
                    src={getLogoPreviousColorToUse(getScoreValue(3,1))}
                  />
                  <View>
                    <Text style={{...getStyle(3,1)}}>{getScoreValue(3,1)}%</Text>
                    <Text style={styles.textEval}>Résultat précédent </Text>
                  </View>
                </View>}
                <View style={styles.textEvalSubContainer} >
                  <Image
                    style={styles.resultLogo}
                    src="/pdf/globalResultLogo.png"
                  />
                  <View>
                    <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(3,2)}%</Text>
                    <Text style={styles.textEval}>Moyenne des répondants </Text>
                  </View>
                </View>
                
              </View>
              
            </View>
          </View>
          <View style={styles.indicatorContainer1}>
            <Text style={styles.textIndicator}>{getIndicatorTitle(3)}</Text>    
            <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(3)}}>{getScoreSentence(3)}</Text>
          </View>
        </View>
      </View>
      {/* page 6 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          2.B. Niveau de maîtrise du plan de vente
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('2.B')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(4,0))}
                />
                <View>
                  <Text style={{...getStyle(4,0)}}>{getScoreValue(4,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(4,1))}
                />
                <View>
                  <Text style={{...getStyle(4,1)}}>{getScoreValue(4,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(4,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(4)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(4)}}>{getScoreSentence(4)}</Text>
        </View>
      </View>
      {/* page 7 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          2.C. Niveau d'adaptabilité durant les entretiens de vente
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('2.C')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(5,0))}
                />
                <View>
                  <Text style={{...getStyle(5,0)}}>{getScoreValue(5,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(5,1))}
                />
                <View>
                  <Text style={{...getStyle(5,1)}}>{getScoreValue(5,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(5,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(5)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(5)}}>{getScoreSentence(5)}</Text>
        </View>
      </View>
      {/* page 8 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
          2.D. Niveau d'assertivité
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('2.D')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(6,0))}
                />
                <View>
                  <Text style={{...getStyle(6,0)}}>{getScoreValue(6,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(6,1))}
                />
                <View>
                  <Text style={{...getStyle(6,1)}}>{getScoreValue(6,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(6,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(6)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(6)}}>{getScoreSentence(6)}</Text>
          <Text style={{...styles.indicatorText ,marginTop:20}}>{getScoreSentenceDefinition(6)}</Text>
        </View>
      </View>
      {/* page 9 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            2.E. Capacité d'écoute
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('2.E')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(7,0))}
                />
                <View>
                  <Text style={{...getStyle(7,0)}}>{getScoreValue(7,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
                {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(7,1))}
                />
                <View>
                  <Text style={{...getStyle(7,1)}}>{getScoreValue(7,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(7,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>
              
            </View>
            
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(7)}</Text>   
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(7)}}>{getScoreSentence(7)}</Text>
        </View>
      </View>
      {/* page 10 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            2.F. Capacité de conviction
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('2.F')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(8,0))}
                />
                <View>
                  <Text style={{...getStyle(8,0)}}>{getScoreValue(8,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(8,1))}
                />
                <View>
                  <Text style={{...getStyle(8,1)}}>{getScoreValue(8,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(8,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(8)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(8)}}>{getScoreSentence(8)}</Text>
        </View>
      </View>
      {/* page 11 */}
      <View style={styles.blockEval2} break>
        <View style={styles.subBlockEval2}> 
          <Text style={styles.subtitleEval}>
            {'>'} Zones de forces et axe de travail sur vos capacités commerciales générales
          </Text>
          <View style={styles.chart1B}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('Eval.1')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <View  style={styles.textEvalmain2}>
                  <Text style={styles.textEvalmain}>A</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',0)}%</Text><Text style={styles.textEvalmain} >: Maîtrise des technique de vente</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2}>
                  <Text style={styles.textEvalmain}>B</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',1)}%</Text><Text style={styles.textEvalmain} > : Maîtrise du plan de vente</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2}>
                  <Text style={styles.textEvalmain}>C</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',2)}%</Text><Text style={styles.textEvalmain} > : adaptabilité durant les entretiens</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>D</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',3)}%</Text><Text style={styles.textEvalmain} > : Niveau d'assertivité</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>E</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',4)}%</Text><Text style={styles.textEvalmain} > : Capacité d'écoute</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>F</Text><Text></Text><Text style={styles.scoreEval}>{getScoreEval('Eval.1',5)}%</Text><Text style={styles.textEvalmain} > : Capacité de conviction</Text>
                </View>
              </View>
            </View> 
          </View>
        </View>
        <View style={styles.indicatorContainer2}>
          <Text style={{...styles.indicatorText ,marginBottom:20, fontWeight:900,color:getWorstScoreColor('Eval.1')}}>{getWorstScoreSentence('Eval.1')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getSecondWorstScoreColor('Eval.1')}}>{getSecondWorstScoreSentence('Eval.1')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getThirdWorstScoreColor('Eval.1')}}>{getThirdWorstScoreSentence('Eval.1')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getFourthWorstScoreColor('Eval.1')}}>{getFourthWorstScoreSentence('Eval.1')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,fontWeight:900,color:getBestScoreColor('Eval.1')}}>{getBestScoreSentence('Eval.1')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getSecondBestScoreColor('Eval.1')}}>{getSecondBestScoreSentence('Eval.1')}</Text>
        </View>
      </View>
      {/* page 12 */}
      <View  break>
          <Text style={styles.titleEval2}>
            3. Analyse détaillée vos capacités commerciales sur les 9 phases clés d'un entretien de vente 
          </Text>
        <View style={styles.blockEval} >
        
          <View style={styles.subBlockEval}>
            
            <Text style={styles.subtitleEval}>
            3.A. Préparation des entretiens
            </Text>
            <View style={styles.chart1A}>
              <Image
                style={styles.chart}
                cache={false}
                src={getBase64ImgUri('3.A')}
              />
              <View style={styles.textEvalContainer}>
                <View style={styles.textEvalSubContainer}>
                  <Image
                    style={styles.resultLogo}
                    src={getLogoActualColorToUse(getScoreValue(9,0))}
                  />
                  <View>
                    <Text style={{...getStyle(9,0)}}>{getScoreValue(9,0)}%</Text>
                    <Text style={styles.textEval}>Résultat actuel </Text>
                  </View>
                </View>
                {questionnary_user_info.totalQuestionnaryDone>1 &&
                <View style={styles.textEvalSubContainer}>
                  <Image
                    style={styles.resultLogo}
                    src={getLogoPreviousColorToUse(getScoreValue(9,1))}
                  />
                  <View>
                    <Text style={{...getStyle(9,1)}}>{getScoreValue(9,1)}%</Text>
                    <Text style={styles.textEval}>Résultat précédent </Text>
                  </View>
                </View>}
                <View style={styles.textEvalSubContainer} >
                  <Image
                    style={styles.resultLogo}
                    src="/pdf/globalResultLogo.png"
                  />
                  <View>
                    <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(9,2)}%</Text>
                    <Text style={styles.textEval}>Moyenne des répondants </Text>
                  </View>
                </View>
                
              </View>
              
            </View>
          </View>
          <View style={styles.indicatorContainer1}>
            <Text style={styles.textIndicator}>{getIndicatorTitle(9)}</Text>    
            <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(9)}}>{getScoreSentence(9)}</Text>
          </View>
        </View>
      </View>
      {/* page 13 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.B. Présentation des entretiens
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.B')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(10,0))}
                />
                <View>
                  <Text style={{...getStyle(10,0)}}>{getScoreValue(10,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(10,1))}
                />
                <View>
                  <Text style={{...getStyle(10,1)}}>{getScoreValue(10,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(10,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(10)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(10)}}>{getScoreSentence(10)}</Text>
        </View>
      </View>
      {/* page 14 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.C. Découverte des besoins et des attentes
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.C')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(11,0))}
                />
                <View>
                  <Text style={{...getStyle(11,0)}}>{getScoreValue(11,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(11,1))}
                />
                <View>
                  <Text style={{...getStyle(11,1)}}>{getScoreValue(11,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(11,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(11)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(11)}}>{getScoreSentence(11)}</Text>
        </View>
      </View>
      {/* page 15 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.D. Reformulation des besoins et des attentes
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.D')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(12,0))}
                />
                <View>
                  <Text style={{...getStyle(12,0)}}>{getScoreValue(12,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(12,1))}
                />
                <View>
                  <Text style={{...getStyle(12,1)}}>{getScoreValue(12,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(12,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(12)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(12)}}>{getScoreSentence(12)}</Text>
        </View>
      </View>
      {/* page 16 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.E. Argumentation des produits / services
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.E')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(13,0))}
                />
                <View>
                  <Text style={{...getStyle(13,0)}}>{getScoreValue(13,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(13,1))}
                />
                <View>
                  <Text style={{...getStyle(13,1)}}>{getScoreValue(13,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(13,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(13)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(13)}}>{getScoreSentence(13)}</Text>
        </View>
      </View>
      {/* page 17 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.F. Traitement des objections
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.F')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(14,0))}
                />
                <View>
                  <Text style={{...getStyle(14,0)}}>{getScoreValue(14,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(14,1))}
                />
                <View>
                  <Text style={{...getStyle(14,1)}}>{getScoreValue(14,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(14,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(14)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(14)}}>{getScoreSentence(14)}</Text>
        </View>
      </View>
      {/* page 18 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.G. Négociation
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.G')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(15,0))}
                />
                <View>
                  <Text style={{...getStyle(15,0)}}>{getScoreValue(15,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(15,1))}
                />
                <View>
                  <Text style={{...getStyle(15,1)}}>{getScoreValue(15,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(15,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(15)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(15)}}>{getScoreSentence(15)}</Text>
        </View>
      </View>
      {/* page 19 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.H. Conclusion des entretiens de vente
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.H')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(16,0))}
                />
                <View>
                  <Text style={{...getStyle(16,0)}}>{getScoreValue(16,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(16,1))}
                />
                <View>
                  <Text style={{...getStyle(16,1)}}>{getScoreValue(16,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(16,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(16)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(16)}}>{getScoreSentence(16)}</Text>
        </View>
      </View>
      {/* page 20 */}
      <View style={styles.blockEval} break>
        <View style={styles.subBlockEval}>
          
          <Text style={styles.subtitleEval}>
            3.I. Suivi des entretiens de vente
          </Text>
          <View style={styles.chart1A}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('3.I')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoActualColorToUse(getScoreValue(17,0))}
                />
                <View>
                  <Text style={{...getStyle(17,0)}}>{getScoreValue(17,0)}%</Text>
                  <Text style={styles.textEval}>Résultat actuel </Text>
                </View>
              </View>
              {questionnary_user_info.totalQuestionnaryDone>1 &&
              <View style={styles.textEvalSubContainer}>
                <Image
                  style={styles.resultLogo}
                  src={getLogoPreviousColorToUse(getScoreValue(17,1))}
                />
                <View>
                  <Text style={{...getStyle(17,1)}}>{getScoreValue(17,1)}%</Text>
                  <Text style={styles.textEval}>Résultat précédent </Text>
                </View>
              </View>}
              <View style={styles.textEvalSubContainer} >
                <Image
                  style={styles.resultLogo}
                  src="/pdf/globalResultLogo.png"
                />
                <View>
                  <Text style={{color:"#008e1e",...styles.textEval}}>{getScoreValue(17,2)}%</Text>
                  <Text style={styles.textEval}>Moyenne des répondants </Text>
                </View>
              </View>  
            </View>
          </View>
        </View>
        <View style={styles.indicatorContainer1}>
          <Text style={styles.textIndicator}>{getIndicatorTitle(17)}</Text>    
          <Text style={{...styles.indicatorText ,color:getScoreSentenceColor(17)}}>{getScoreSentence(17)}</Text>
        </View>
      </View>
      {/* page 21 */}
      <View style={styles.blockEval2} break>
        <View style={styles.subBlockEval2}> 
          <Text style={styles.subtitleEval}>
            {'>'} Zones de forces et axes de travail sur les 9 phases clés d'un entretien de vente
          </Text>
          <View style={styles.chart1B}>
            <Image
              style={styles.chart}
              cache={false}
              src={getBase64ImgUri('Eval.2')}
            />
            <View style={styles.textEvalContainer}>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>A</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',0)}% </Text><Text style={styles.textEvalmain}>: Préparation</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>B</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',1)}% </Text><Text style={styles.textEvalmain}> : Présentation</Text>
                </View>
              </View >
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>C</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',2)}% </Text><Text style={styles.textEvalmain}> : Découverte</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>D</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',3)}% </Text><Text style={styles.textEvalmain}> : Reformulation</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>E</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',4)}% </Text><Text style={styles.textEvalmain}> : Argumentation</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>F</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',5)}% </Text><Text style={styles.textEvalmain}> : Traitement des objections</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>G</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',6)}% </Text><Text style={styles.textEvalmain}> : Négociation</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>H</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',7)}% </Text><Text style={styles.textEvalmain}> : Conclusion</Text>
                </View>
              </View>
              <View style={styles.textEvalSubContainer}>
                <View style={styles.textEvalmain2} >
                  <Text style={styles.textEvalmain}>I</Text><Text>          </Text><Text style={styles.scoreEval}>{getScoreEval('Eval.2',8)}% </Text><Text style={styles.textEvalmain}> : Suivi</Text>
                </View>
              </View>
            </View> 
          </View>
        </View>
        <View style={styles.indicatorContainer2}>
          <Text style={{...styles.indicatorText ,marginBottom:20, fontWeight:900,color:getWorstScoreColor('Eval.2')}}>{getWorstScoreSentence('Eval.2')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getSecondWorstScoreColor('Eval.2')}}>{getSecondWorstScoreSentence('Eval.2')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getThirdWorstScoreColor('Eval.2')}}>{getThirdWorstScoreSentence('Eval.2')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getFourthWorstScoreColor('Eval.2')}}>{getFourthWorstScoreSentence('Eval.2')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,fontWeight:900,color:getBestScoreColor('Eval.2')}}>{getBestScoreSentence('Eval.2')}</Text>
          <Text style={{...styles.indicatorText ,marginBottom:20,color:getSecondBestScoreColor('Eval.2')}}>{getSecondBestScoreSentence('Eval.2')}</Text>
        </View>
      </View>
      {/* page 22 */}
      <View style={styles.blockEvalQuestionsRecall} break>
          <Text style={styles.blockEvalQuestionsRecallTitle}>
           ENSEMBLE DES QUESTIONS
          </Text>
          <Text style={styles.separator}>
           ......................................................................................
          </Text>
          <Text style={styles.blockEvalQuestionsRecallSubTitle}>
            ÉTAPE - 1
          </Text>
          <Text style={styles.separator}>
           ......................................................................................
          </Text>
          <View style={styles.blockEvalQuestionsRecallSubContainer}>
            <View>
              <Text>Avant de commencer merci de renseigner</Text>
            </View>
            <View >
              <View style={styles.blockEvalQuestionsRecallSubContainer2}> 
                <Text style={{ marginRight:150 , marginBottom:20,color:'#84888b'}}>Nom :</Text><Text style={{ marginRight:70,fontWeight:900}} >{questionnary_user_info.user_firstname}</Text>
              </View>
              <View style={styles.blockEvalQuestionsRecallSubContainer2}>
                <Text style={{ marginRight:150 , marginBottom:20,color:'#84888b'}} >Prénom :</Text><Text style={{ marginRight:70,fontWeight:900 }}>{questionnary_user_info.user_lastname}</Text>
              </View>
            </View>
          </View>
          <Text style={styles.blockEvalQuestionsRecallSubTitle}>
            ÉTAPE - 2
          </Text>
          <Text style={styles.separator}>
           ......................................................................................
          </Text>

          <View style={{display:'flex',flexDirection:'row', justifyContent:'center'}}>
            <View style={styles.indicatorContainer3}>
                {getQuestionUser(0)}
            </View>
            <View style={styles.indicatorContainer3}>
                {getAnswersUser(0)}
            </View>
        </View>
      </View>
      {/* page 23 */}
      {generateRestOfQuestions()}
      {/* page des question+réponse au dela de la question 1 */}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber}`
      )} fixed />
    </Page>
  </Document>
  )
}

Font.register({
family: 'Oswald',
src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
body: {
  // paddingTop: 35,
  // paddingBottom: 65,
  // paddingHorizontal: 35,
},
title: {
  fontSize: 24,
  textAlign: 'center',
  fontFamily: 'Oswald'
},
author: {
  fontSize: 12,
  textAlign: 'center',
  marginBottom: 40,
},
subtitle: {
  fontSize: 18,
  margin: 12,
  fontFamily: 'Oswald',
  textAlign: 'end',
},
text: {
  margin: 12,
  fontSize: 14,
  textAlign: 'justify',
  fontFamily: 'Times-Roman'
},
image: {
  marginVertical: -5,
  marginHorizontal: 0,
  width: 850,
  height: 500,
  // backgroundColor: '#ad1380',
},
chart: {
  marginVertical: 32,
  marginHorizontal: 50,
  width: 300,
},
header: {
  flex: 1,
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
  marginBottom: 50,
  height: 50,
  maxHeight: 50,
  textAlign: 'center',
  color: 'white',
  backgroundColor: '#ad1380',
  padding: 0,
},
headerText: {
  flex: 1,
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
  marginTop: 12,
  height: 50,
  maxHeight: 50,
  textAlign: 'center',
  color: 'white',
  padding: 0,
},
pageNumber: {
  position: 'absolute',
  fontSize: 12,
  bottom: 30,
  left: 0,
  right: 0,
  textAlign: 'center',
  color: 'grey',
},
titleFirstPage: {
  color: '#ad1380',
  fontSize: 24,
  textAlign: 'center',
},
separator: {
  color: '#ad1380',
  fontSize: 28,
  textAlign: 'center',
},
timer: {
  fontSize: 18,
  textAlign: 'end',
  marginBottom: 20,
  marginLeft: 450,
},
coordonate: {
  flex: 1,
  flexDirection: 'row',
  alignItems: 'start',
  alignContent: 'start',
  marginTop: -60,
  marginLeft: 50,
  
},
subcoordonate: {
  flex: 1,
  flexDirection: 'column',
  width: 150,
  justifyContent: 'center',
},
subcoordonateText: {
  fontWeight: '900',
  fontSize: 20,
  marginBottom: 20,
},
subcoordonateText2: {
  fontWeight: '900',
  fontSize: 15,
  marginBottom: 20,
},
blockEval: {
  marginLeft: 40,
  
} ,
blockEval2: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: 40,
  marginRight: 40,
  alignItems: 'start',
  
} ,
titleEval: {
  marginBottom: 50,
  fontSize: 27,
},
titleEval2: {
  marginBottom: 50,
  fontSize: 24,
  marginLeft: 40,
  marginRight: 40,
},
subBlockEval: {
  display: 'flex',
  border: '1px solid black',
  padding: 20,
  width: 700,

} ,
chart1A: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
},
chart1B: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
},
textEvalContainer: {
  fontSize: 10,
},
textEvalSubContainer: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 30,
},
resultLogo: {
  // marginVertical: -5,
  // marginHorizontal: 0,
  width: 40,
  height: 40,
},
indicatorContainer1: {
  marginTop: 35,
  border: '1px solid black',
  padding: 20,
  width: 700,
},
indicatorContainer2: {
  border: '1px solid black',
  padding: 20,
  width: 350,
  minHeight: 400,
},
textIndicator: {
  fontSize: 15,
  marginBottom: 20,
  fontWeight: '900',
},
subDate: {
  fontSize: 12,
  marginLeft: 600,
  marginBottom: 40,
},
subBlockEval2: {
  display: 'flex',
  border: '1px solid black',
  flexDirection: 'column',
  padding: 20,
  width: 350,

} ,
textEval: {
  fontSize: 16,
},
textEvalmain: {
  fontSize: 15,
},
blockEvalQuestionsRecall:{
  display: 'flex',
  flexDirection: 'column',
},
blockEvalQuestionsRecallTitle:{
  fontSize: 24,
  textAlign: 'center',
},
blockEvalQuestionsRecallSubTitle:{
  fontSize: 16,
  textAlign: 'center',
  marginTop: 20,
  marginBottom: 20,
},
blockEvalQuestionsRecallSubContainer:{
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: 60,
  marginRight: 60,
  marginTop: 30,
  alignItems: 'start',
  fontSize: 10,
  borderBottom:'1px solid #84888b'
},
blockEvalQuestionsRecallSubContainer2:{
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textAlign: 'center',
  
},
subBlockEval3: {
  display: 'flex',
  border: '1px solid black',
  flexDirection: 'column',
  padding: 20,
  width: 350,
  marginLeft: 40,
  marginRight: 40,
  marginTop: 40,
  
},
indicatorContainer3: {
  padding: 20,
  width: 350,
  minHeight: 400,
  fontSize: 10,
  textAlign: 'justify',
},
indicatorText: {
  fontSize: 17,
  fontWeight: '900',
},
scoreEval: {
  fontWeight: '900',
  marginLeft: 50,
  marginRight: 3,
  fontSize: 15,
},
textEvalmain2: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
},
softSeparator: {
  borderBottom:'1px solid #84888b',
  marginBottom: 20,
  marginTop: 20,
  marginLeft: 60,
  marginRight: 60,
}
});

export default PdfQuestionnaryDocument;