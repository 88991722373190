import './App.css';
import { Routes,Route,useNavigate , useLocation} from "react-router-dom"
import { useEffect ,useState} from 'react';
import DraggableQuestionnary from './components/draggableQuestionnary';
import Identification from './components/identification';
import axios from "axios";
import Error404 from './components/error404';
import Home from './components/home';
import QuestionnaryFinished from './components/questionnaryFinished';
import PdfQuestionnaryDocument from './components/pdfQuestionnaryDocument';
import PdfChartGenerator from './components/pdfChartGenerator';
import { PDFViewer } from '@react-pdf/renderer';
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let [appStore, setAppStore] = useState({
    firstname: "",
    lastname: "",
    email:"",
    userValidated:false,
    questionnary_user_id:"",
    env:{
      'localhost':'local',
      'dev.ea-assessment.com':'dev',
      'ea-assessment.com':'prod'
    },
    baseUrl:{
      local:"http://localhost:9001",
      dev:`${window.location.protocol}//dev.ea-assessment.com/api`,
      prod:`${window.location.protocol}//ea-assessment.com/api`
    },
  })
  const baseUrl = appStore.baseUrl[appStore.env[window.location.hostname]]
  const reset=()=>{
    setAppStore({...appStore,firstname: "",
    lastname: "",
    email:"",
    userValidated:false,
    questionnary_user_id:"",})

  }
  const checkUser=(appStore)=>{
    if(appStore == null){
      return navigate('/')
    }
    if(appStore.userValidated && appStore.questionnary_user_id.length){
      axios.get(`${baseUrl}/checkuser/${appStore.questionnary_user_id}`).then((res) => {
        console.log(res.data)
        if(res.data.status!=="200"){
          reset()
          navigate("/")
        }
       return navigate(`/q/${res.data.questionnary_id}/${res.data.current_question_id}`);
      });
     }
    
    if(!appStore.userValidated && window.location.pathname.includes("/q/")){
      reset()
      navigate("/")
    }
  }
  useEffect(() => {
    
    if(sessionStorage.getItem("appStore")){
      setAppStore(JSON.parse(sessionStorage.getItem("appStore")))
    }
    if(window.location.pathname.includes("/q/")){
      
      checkUser(JSON.parse(sessionStorage.getItem("appStore")))
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem("appStore",JSON.stringify(appStore))
    
    
  }, [appStore]);

  return (
    <>
    <div id="app" className="d-md-flex justify-content-center">
        
      <Routes>
        <Route path="/" element={<Home baseUrl={baseUrl} appStore={appStore} setAppStore={setAppStore} />} />
          <Route path="/identification/:questionnary_id" name="questionnary_ident" element={<Identification baseUrl={baseUrl} appStore={appStore} setAppStore={setAppStore}/>} />
          <Route path="/q/:questionnary_id/:question_id" name="questionnary_exec" element={<DraggableQuestionnary baseUrl={baseUrl} appStore={appStore} setAppStore={setAppStore} />} />
          <Route path="/404" name="questionnary_404" element={<Error404 baseUrl={baseUrl} appStore={appStore} />} />
          <Route path="/pdfshow/:questionnary_user_id" name="pdf_show" element={<PdfChartGenerator baseUrl={baseUrl} appStore={appStore} setAppStore={setAppStore} />} />
          <Route path="/questionnary/finished" name="questionnary_finished" element={<QuestionnaryFinished baseUrl={baseUrl} appStore={appStore} setAppStore={setAppStore} />} />
      </Routes>
       
    </div>
    </>
  )
}

export default App;