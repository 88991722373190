import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useState ,useEffect} from 'react';
import { useNavigate ,useParams} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const qs = require('qs');
const Identification=({baseUrl,appStore,setAppStore})=>{
    const [visible, setVisible] = useState(false);
    const [textDialog, setTextDialog] = useState("Veuillez saisir vos informations");
    const [disabledStatus, setDisabledStatus] = useState('');
    const [recaptchaStatus, setRecaptchaStatus] = useState(false);
    const [tokenRecaptcha , setTokenRecaptcha] = useState('');
    let { questionnary_id} = useParams();

    let navigate = useNavigate();
    
    const handleChangeRecaptcha = (value) => {
        setTokenRecaptcha(value);
        setRecaptchaStatus(true);
    }
    const handleExpiredRecaptcha=(e)=>{
        setTokenRecaptcha('');
        setRecaptchaStatus(false)
    }
    const handleErroredRecaptcha=(e)=>{
        console.log("error")
        setTokenRecaptcha('');
        setRecaptchaStatus(false)
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setDisabledStatus('disabled')
        if(appStore.firstname.length>0 && appStore.lastname.length>0 && appStore.email.length>0 && recaptchaStatus){
            console.log('userValidated : true')
            
            let data = qs.stringify({
                userInfo:{user_firstname:appStore.firstname,user_lastname:appStore.lastname,user_email:appStore.email,questionnary_id:questionnary_id},
                token:tokenRecaptcha
            });
            axios.post(`${baseUrl}/register/q`,data).then((res)=>{
                
                console.log(res)
                if(res.data.status===false){
                    setTextDialog("Une erreur est survenue, veuillez réessayer")
                    setDisabledStatus('')
                    setVisible(true)
                }else{
                    sessionStorage.setItem("appStore",JSON.stringify({...appStore,userValidated:true,questionnary_user_id:res.data.questionnary_user_id}))
                    return navigate(`/q/${questionnary_id}/1`);
                   
                    
                    // return navigate(`/`);
                }
            }) 

        
      
            
        }else{
            setDisabledStatus('')
            setTextDialog("Veuillez saisir vos informations")
            setVisible(true)
        }

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setAppStore({ ...appStore, [name]: value });    
    }


    const onHide = () => {
        setVisible(false);
    }


    if(appStore.userValidated === false){
        return (
            <>
            <Dialog visible={visible} onHide={onHide} breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '20vw'}}>
                {textDialog}
            </Dialog>
            <div className='d-flex justify-content-center'>
                <form onSubmit={handleFormSubmit}>
                    <div className='mt-3 mb-3 textBoldQuestion titreColor'>
                        Veuillez saisir vos informations
                    </div>
                    <div className="form-group mt-1">
                        <label >Nom</label>
                        <InputText  type="text" className="form-control" id="firstname" name="firstname" required value={appStore.firstname} onInput={handleChange} aria-describedby="emailHelp" placeholder="" />
                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    <div className="form-group mt-1">
                        <label >Prénom</label>
                        <InputText  type="text" className="form-control" id="lastname" name="lastname" required value={appStore.lastname} onInput={handleChange} aria-describedby="emailHelp" placeholder="" />
                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    <div className="form-group mt-1">
                        <label >Email</label>
                        <InputText  type="email" className="form-control" id="email" name="email" required value={appStore.email} onInput={handleChange} aria-describedby="emailHelp" placeholder="" />
                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    <div className="form-group mt-1">
                        <ReCAPTCHA
                            sitekey="6LceYoMiAAAAAEjm-mFy3ZsmAw8J-eDh0AlbNzyz"
                            onChange={handleChangeRecaptcha}
                            onExpired={handleExpiredRecaptcha}
                            onErrored={handleErroredRecaptcha}
                        />
                    </div>
                    <button type="submit" disabled={disabledStatus} className="btn btn-success mt-3">Valider</button>
                </form>
            </div>
            </>
        )
    }else{
        {navigate(`/q/${questionnary_id}/1`)}
    }
}

export default Identification;