import { useEffect } from "react"

const QuestionnaryFinished=({setAppStore})=>{
  useEffect(() => {
    sessionStorage.removeItem("appStore")
    setAppStore({
        firstname: "",
        lastname: "",
        email:"",
        userValidated:false,
        questionnary_user_id:"",
        env:{
          'localhost':'local',
          'dev.ea-assessment.com':'dev',
          'ea-assessment.com':'prod'
        },
        baseUrl:{
          local:"http://localhost:9001",
          dev:`${window.location.protocol}//dev.ea-assessment.com/api`,
          prod:`${window.location.protocol}//ea-assessment.com/api`
        },
      })
  }, []);
   
    return(
        <div className="QuestionnaryFinished">
            <div className="QuestionnaryFinished__content">
                <h1 className="QuestionnaryFinished__content__title">Questionnaire terminé !</h1>
                <p className="QuestionnaryFinished__content__text">Merci d'avoir réalisé ce questionnaire</p>
            </div>
        </div>
    )
}

export default QuestionnaryFinished;