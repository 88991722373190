import React, { useEffect } from 'react';
import { Page, Text, View, Document, Image,Font,StyleSheet ,BlobProvider , PDFDownloadLink} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { useParams} from "react-router-dom";
import axios from "axios";
import PdfQuestionnaryDocument from "../pdfQuestionnaryDocument"
import ChartsGenerator from './chartsGeneratorFunction'
// Create styles
const PdfChartGenerator=({baseUrl})=>{
  let [isImgUriFullyLoaded, setIsImgUriFullyLoaded] = React.useState(false);
  let [imgUriArray, setImgUriArray] = React.useState([]);
  let { questionnary_user_id} = useParams();
  let [chartsLength, setChartsLength] = React.useState(100);
  let [charts, setCharts] = React.useState([]);
  let [questionnary_user_info, setQuestionnary_user_info] = React.useState({});
  let [isLoading, setIsLoading] = React.useState(true);
  let [totalQuestionnaryDone, setTotalQuestionnaryDone] = React.useState(0);
  useEffect( () => {
      try {
          sessionStorage.removeItem('chartURIs')
          axios.get(`${baseUrl}/questionnary_user/getchartdata/${questionnary_user_id}`).then((res)=>{
              setTotalQuestionnaryDone(res.data.questionnary_user.totalQuestionnaryDone)
              setChartsLength(res.data.questionnary_user.chart.length)
              setCharts(res.data.questionnary_user.chart)
              setQuestionnary_user_info(res.data.questionnary_user)
              setTimeout(() => {
                  setIsLoading(false)
              }, 1000);
          })
      }catch (e) {
          console.log(e)
      }


   }, []);

   useEffect( () => {
    if(imgUriArray.length==chartsLength){
        setIsImgUriFullyLoaded(true)
    }
   }, [imgUriArray]);

  return(
      <>
        <div className='d-flex justify-content-center flex-column align-items-center'>
          {!isImgUriFullyLoaded &&
            <>
              <div>PDF en cours génération
                <div className='d-none'>
                {
                  charts.map((chart,index)=>{
                      try {
                          return ChartsGenerator(chart,setImgUriArray,totalQuestionnaryDone)
                      }catch (e) {
                        console.log(e)
                      }

                  })
                }
                </div>
              </div>
              </>
          }
            {/* <PDFDownloadLink document={<PdfQuestionnaryDocument />} fileName="somename.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Lien personalisé')}
            </PDFDownloadLink> */}
          {isImgUriFullyLoaded &&
          <>
            <PDFViewer width={'1500px'} height={'1000px'}>
              <PdfQuestionnaryDocument imgUriArray={imgUriArray} questionnary_user_info={questionnary_user_info} />
            </PDFViewer>
            </>
          }

        </div>

      </>

  )

}

export default PdfChartGenerator