const Error404=()=>{
    return(
        <div className="error404">
            <div className="error404__content">
                <h1 className="error404__content__title">404</h1>
                <p className="error404__content__text">Page non trouvé</p>
            </div>
        </div>
    )
}

export default Error404;