
import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import { useEffect, useState } from "react";

let ChartsGeneratorFunction =(chart,setImgUriArray,totalQuestionnaryDone)=>{
    // // Vérification des données du scoreChart
    let scoreChart = chart.scoreChart || [];  // Utilisation d'une valeur par défaut vide si undefined

    // Filtrage si c'est nécessaire
    if (totalQuestionnaryDone === 1 && chart.type === 'radialBar') {
        chart.scoreChart = chart.scoreChart.map(scoreValue => scoreValue === null ? 0 : scoreValue);
    }

    // Vérification de l'existence avant de mapper
    if (chart.scoreChart !== undefined && chart.scoreChart !== null) {
        scoreChart = chart.scoreChart.map(scoreValue => {
            return Math.round(scoreValue);
        });
    }

  let getColor=(score)=>{
    if(score==undefined){
      return ''
    }
    if(score>=0 && score<50){
      return "#f8951d"
    }else if(score>=50 && score<74){
      return "#f2c037"
    }else if(score>=74 && score<=100){
      return "#07d530"
    }
  }
  
  if(chart.type=="radialBar"){

    let chartData= {

      series: scoreChart,
      options: {
        chart: {
          dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5
            },
          events:{
            mounted: function(chartContext, config) {

              let chartURIs;
              setTimeout(() => {
              ApexCharts.exec(chart.chart_id, "dataURI").then(({ imgURI }) => {
                  if(sessionStorage.getItem('chartURIs')===null){
                    sessionStorage.setItem('chartURIs',JSON.stringify([{[chart.chart_id]:imgURI}]))
                    chartURIs=[{[chart.chart_id]:imgURI}]
                  }else{
                    chartURIs=JSON.parse(sessionStorage.getItem('chartURIs'))
                    chartURIs.push({[chart.chart_id]:imgURI})
                    sessionStorage.setItem('chartURIs',JSON.stringify(chartURIs))
                  }
                  setImgUriArray([...chartURIs])

                })
              }, 3500);

            }
          },
          id: `${chart.chart_id}`,
          height: 350,
          type: 'radialBar',
        },

        plotOptions: {
          radialBar: {
              hollow: {
                margin: 30,
                size: "10%"
              },
              track: {
                  show: true,
                  startAngle: undefined,
                  endAngle: undefined,
                  background: '#b8b2b2',
                  strokeWidth: '100%',
                  opacity: 1,
                  margin: 15,

              },
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
              },
              total: {
                show: false,
                label: 'Total',
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249
                }
              }
            },
          }
        },
        fill: {
          type: ['solid'],
          colors: totalQuestionnaryDone>1?[chart.scoreChart!==undefined?getColor(chart.scoreChart[0]):'', chart.scoreChart!==undefined?getColor(chart.scoreChart[1]):'',"#35e3d2"]:[chart.scoreChart!==undefined?getColor(chart.scoreChart[0]):'',"#35e3d2"]
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          width: 7,
          dashArray: 0,
        },
        labels: ['', '', ''],
      },


    }

    return(

      <Chart
          key={chart.chart_id}
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          width="500"
        />

    )
  }
  
  if(chart.type=="radar"){
    
    let categoriesList = chart.score_sentence.map((score) => {
      return Object.keys(score)[0].substring(3,2)
    });
    let globalScoreChart = chart.score_sentence.map((score) => {
      let key = Object.keys(score)[0]
      //index 0 actual , index 1 previous , index 2 global
      // console.log(score[key].score[2])
      return Math.round(score[key].score[2])
    });
    let previousScoreChart = chart.score_sentence.map((score) => {
     let key = Object.keys(score)[0]
     return Math.round(score[key].score[1])
    });
    let actualScoreChart = chart.score_sentence.map((score) => {
     let key = Object.keys(score)[0]
     return Math.round(score[key].score[0])
    });
    // console.log(categoriesList,globalScoreChart)

    return(
      
       <Chart
          key={chart.chart_id}  
                  options={{
                    chart: {
                      dropShadow: {
                          enabled: true,
                          top: 0,
                          left: 0,
                          // blur: 3,
                          // opacity: 0.5
                        },
                      events:{
                        mounted: function(chartContext, config) {
                            
                          
                          let chartURIs;
                          setTimeout(() => {
                          ApexCharts.exec(chart.chart_id, "dataURI").then(({ imgURI }) => {
                              if(sessionStorage.getItem('chartURIs')===null){
                                sessionStorage.setItem('chartURIs',JSON.stringify([{[chart.chart_id]:imgURI}]))
                                chartURIs=[{[chart.chart_id]:imgURI}]
                              }else{
                                chartURIs=JSON.parse(sessionStorage.getItem('chartURIs'))
                                chartURIs.push({[chart.chart_id]:imgURI})
                                sessionStorage.setItem('chartURIs',JSON.stringify(chartURIs))
                              }
                              setImgUriArray([...chartURIs])
                              
                            })
                          }, 3500);
                            
                        }
                      },
                      id: `${chart.chart_id}`,
                      type: 'radar',
                    },
                    xaxis: {
                      categories: [...categoriesList],
                      labels: {
                        show: true,
                        style: {
                          colors: ["#a8a8a8"],
                          fontSize: "50px",
                          fontFamily: 'Arial',
                          // fontWeight: 900,
                        }
                      },
                    },
                    yaxis: {
                      tickAmount: 4,
                      min: 0,
                      max: 100,
                    },
                    labels: {
                      show: true,
                      style: {
                        colors: ["#a8a8a8"],
                        fontSize: "28px",
                        fontFamily: 'Arial',
                        fontWeight: 400,
                      }
                    },
                    fill: {
                      type: "solid"
                    },
                    stroke: {
                      show: true,
                      curve: "smooth",
                      lineCap: "butt",
                      colors: undefined,
                      width: 6,
                      dashArray: 0
                    },
                    markers: {
                      size: 10,
                      hover: {
                        size: 10
                      }
                    },
                    legend: {
                      show: true,
                      fontSize: '28px',
                      itemMargin: {
                        horizontal: 30,
                        vertical: 0
                      },
                      
                      markers: {
                        width: 20,
                        height: 20,
                        radius: 0,
                      },
                    }
                  }}
                  series={totalQuestionnaryDone>1?[
                      {
                          name: "Actuel",
                          data: actualScoreChart,
                          color:"#F5D602",
                          size: 20,
                      },
                      {
                          name: "Précédent",
                          data: previousScoreChart,
                          color:"#fa591b",
                      },
                      {
                          name: "Moyenne",
                          data: globalScoreChart,
                          color:"#27d1be",
                      }
                  ]:[
                      {
                          name: "Actuel",
                          data: actualScoreChart,
                          color:"#F5D602",
                          size: 20,
                      },
                      {
                          name: "Moyenne",
                          data: globalScoreChart,
                          color:"#27d1be",
                      }
                  ]}
                        type="radar"
                        width="650"
                        height="850"
                />
      
    )
  }


}

export default ChartsGeneratorFunction;
                
              