import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/partials/header';
import Footer from './components/partials/footer';
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

document.getElementById('root').style.visibility = 'visible';
document.getElementById('root').style.opacity = 1;
ReactDOM.render(
  <BrowserRouter>
  <Header />
  <App />
  <Footer />
</BrowserRouter>,
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
