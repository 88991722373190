import React, { useState,useEffect } from "react";
import "./draggableQuestionnary.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate,useParams } from "react-router-dom"
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
const qs = require('qs');
let DraggableQuestionnary=({baseUrl,appStore,setAppStore})=> {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [currentQuestionText, setCurrentQuestionText] = useState("");
  const [currentAnswerText, setCurrentAnswerText] = useState([]);
  const [questionnary_length, setQestionnary_length] = useState(0);
  const [disabledStatus, setDisabledStatus] = useState('');
  // React state to track order of items
  const [itemList, setItemList] = useState([]);


  const[currentQuestionInstruction,setcurrentQuestionInstruction]=useState("Classez les propositions de 1 à 4 en les faisant glisser : 1 étant votre 1er choix, 2 votre 2ème , 3 votre 3ème , 4 votre 4ème.");
  let navigate = useNavigate();
  let { questionnary_id , question_id} = useParams();
  useEffect(() => {
    try {
      console.log(appStore)
      console.log(questionnary_id,question_id)
      if(questionnary_id===undefined || question_id===undefined){
        return navigate("/")
      }
      axios.get(`${baseUrl}/q/${questionnary_id}/${question_id}`).then((res) => {

        if(res.data.error){
          setTimeout(() => {
            navigate("/")
          }, 1500);
          return onClick('displayBasic')
        }
        
        setQestionnary_length(res.data.questionnary_length)
        setCurrentQuestionText(res.data.questionText)
        // setCurrentAnswerText(res.data.responseList.map((answer) => answer.answerText).reduce((acc, answer) => acc.concat(answer), []))
        setCurrentAnswerText(res.data.responseList)
        
      });
    } catch (error) {
      console.log(error)
      navigate("/")
    }
    if(sessionStorage.getItem("appStore")){
      setAppStore(JSON.parse(sessionStorage.getItem("appStore")))
    }
  }, []);
  useEffect(() => {
    try {
      console.log(appStore)
      console.log(questionnary_id,question_id)
      axios.get(`${baseUrl}/q/${questionnary_id}/${question_id}`).then((res) => {
        console.log(res.data)
        if(res.data.error){
          setTimeout(() => {
            navigate("/")
          }, 1500);
          return onClick('displayBasic')
        }
        
        setQestionnary_length(res.data.questionnary_length)
        setCurrentQuestionText(res.data.questionText)
        // setCurrentAnswerText(res.data.responseList.map((answer) => answer.answerText).reduce((acc, answer) => acc.concat(answer), []))
        setCurrentAnswerText(res.data.responseList.sort((a, b) => 0.5 - Math.random()))
        
      });
    } catch (error) {
      console.log(error)
      navigate("/")
    }
    
  }, [question_id]);
  useEffect(() => {
    setItemList(currentAnswerText)
  }, [currentQuestionIndex, currentQuestionText, currentAnswerText]);
  
  const handleValidationAnswer=(e)=>{
    e.preventDefault();
    // setDisabledStatus('disabled')
    
    let data = {
      questionnary_user_id:appStore.questionnary_user_id,
      questionnary_id:questionnary_id,
      question_order:question_id,
      answerList:itemList
    }
    qs.stringify(data)
    axios.post(`${baseUrl}/questionnary_user/addresponse`,data).then((res)=>{

      if(res.data.status!=="200"){
        setDisplayBasic("displayModal")
        setDisabledStatus('')
        return
      }
      if(isNaN(parseInt(res.data.next_question_id))){
        return navigate(`/questionnary/finished`)
        
      }else{
        setDisabledStatus('')
        return navigate(`/q/${questionnary_id}/${res.data.next_question_id}`);
      }
      

    })
    console.log(data)
  }

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    
    setItemList(updatedList);
  };
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [displayPosition, setDisplayPosition] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');

  const dialogFuncMap = {
      'displayBasic': setDisplayBasic,
      'displayBasic2': setDisplayBasic2,
      'displayModal': setDisplayModal,
      'displayMaximizable': setDisplayMaximizable,
      'displayPosition': setDisplayPosition,
      'displayResponsive': setDisplayResponsive
  }

  const onClick = (name, position) => {
      dialogFuncMap[`${name}`](true);

      if (position) {
          setPosition(position);
      }
  }

  const onHide = (name) => {
      dialogFuncMap[`${name}`](false);
  }
  const renderError = (name) => {
    return (
        <div>
            <Button label="Ok" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
        </div>
    );
}
  return (
    <>

<Dialog header="Oups..." visible={displayBasic} style={{ width: '20vw' }} footer={renderError('displayBasic')} onHide={() => onHide('displayBasic')}>
    <p>Une erreur est survenue</p>
</Dialog>
    <div className="d-flex flex-column m-3 ">
      <div className="ml-3 boxQuestionInfo">{question_id} / {questionnary_length}</div>
      <div className="d-flex justify-content-between">
      
      <div className="mr-3 textBoldQuestion">{currentQuestionText}</div>
      </div>
      <div className="mr-3 ">{currentQuestionInstruction}</div>
      <div className="draggableQuestionnaryContainer">
        
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {itemList.map((item, index) => (
                  
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided) => (
                      <div
                        className="item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >

                        {index+1+" - "} {item}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    { 
      itemList.length>1  &&   <>             
        <div className="d-flex justify-content-center">
          {/* {
          currentQuestion > 1 && 
          <>
            <button className="btn btn-primary m-2">Précédent</button>
          </>
          } */}
          
          <button className="btn btn-success m-2" disabled={disabledStatus} onClick={handleValidationAnswer}>Valider</button>
          {/* {
          currentQuestion+1 < questionnary_length && 
          <>
            <button className="btn btn-primary m-2">Suivant</button>
          </>
          } */}
          
        </div>
      </> 
    }                  

    </div>
    </>
    
  );
}

export default DraggableQuestionnary;